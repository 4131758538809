import './App.css';
import './languages/i18n';
import Content from './components/Content.js'

function StartPage() {
    return (
        <Content/>
    );
}

export default StartPage;
