import './Background.scss';

function BackgroundBlock() {
    return (
        <div id="Background">
            <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
        </div>
    );
}

export default BackgroundBlock;